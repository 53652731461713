/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: "Work Sans", "Helvetica Neue", sans-serif;
}

/* work-sans-300 - latin */
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 300;
  src: url("/assets/fonts/work-sans-v3-latin-300.eot"); /* IE9 Compat Modes */
  src: local("Work Sans Light"), local("WorkSans-Light"),
    url("/assets/fonts/work-sans-v3-latin-300.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("/assets/fonts/work-sans-v3-latin-300.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("/assets/fonts/work-sans-v3-latin-300.woff")
      format("woff"),
    /* Modern Browsers */ url("/assets/fonts/work-sans-v3-latin-300.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("/assets/fonts/work-sans-v3-latin-300.svg#WorkSans") format("svg"); /* Legacy iOS */
}

/* work-sans-regular - latin */
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  src: url("/assets/fonts/work-sans-v3-latin-regular.eot"); /* IE9 Compat Modes */
  src: local("Work Sans"), local("WorkSans-Regular"),
    url("/assets/fonts/work-sans-v3-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("/assets/fonts/work-sans-v3-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("/assets/fonts/work-sans-v3-latin-regular.woff") format("woff"),
    /* Modern Browsers */ url("/assets/fonts/work-sans-v3-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("/assets/fonts/work-sans-v3-latin-regular.svg#WorkSans") format("svg"); /* Legacy iOS */
}

/* work-sans-600 - latin */
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  src: url("/assets/fonts/work-sans-v3-latin-600.eot"); /* IE9 Compat Modes */
  src: local("Work Sans SemiBold"), local("WorkSans-SemiBold"),
    url("/assets/fonts/work-sans-v3-latin-600.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("/assets/fonts/work-sans-v3-latin-600.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("/assets/fonts/work-sans-v3-latin-600.woff")
      format("woff"),
    /* Modern Browsers */ url("/assets/fonts/work-sans-v3-latin-600.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("/assets/fonts/work-sans-v3-latin-600.svg#WorkSans") format("svg"); /* Legacy iOS */
}

.mat-toolbar button {
  background-color: inherit;
  height: 100%;
  width: 8rem;
  border-radius: 0;
  color: white;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
